import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// https://codebushi.com/form-handling-gatsby-netlify/

const SuccessPage = () => (
  <Layout>
    <SEO title="Message received"/>
    <section id="about">
      <div className="container">
        <div className=" t2-padding">
          <h1>Let's talk about your project</h1>
          <h4>Impactful Image annotation for AI Applications.</h4>
        </div>
      </div>
    </section>

    <section id="white-ground" className="t-padding b-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Message received</h1>
            <p>We'll contact you soon. <span role="img" aria-label="">😎</span></p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SuccessPage
